
/*-------------------
     Transitions
--------------------*/

/*--- Custom Colors ---*/
.omniSection {
    background-color: @omniSectionColor !important;
} 

.omniHeaderDark {
    background-color: @omniTextColor !important;
    color: #FFFFFF !important;
}

.omniHeaderLight {
    background-color: @omniHeaderLightColor !important;
}

.omniPanel1 {
    border-radius: 0;
    background-color: #E2EBEC !important;
    -webkit-box-shadow: 0 2px 2px 0 rgba(32, 32, 32,0.20) !important;
    -moz-box-shadow: 0 2px 2px 0 rgba(32, 32, 32,0.20) !important;
    box-shadow:  0 2px 2px 0 rgba(32, 32, 32,0.20) !important;
}

.omniPanel2 {
    border-radius: 0;
    background-color: #C5CFD5 !important;
    -webkit-box-shadow: 0 2px 2px 0 rgba(32, 32, 32,0.20) !important;
    -moz-box-shadow: 0 2px 2px 0 rgba(32, 32, 32,0.20) !important;
    box-shadow:  0 2px 2px 0 rgba(32, 32, 32,0.20) !important;
}

