/*******************************
    Dropdown Theme Overrides
********************************/
.ui .search .selection .dropdown {
    width: 100%;
}

.ui.dropdown.selection {
    min-height: 30px;
    line-height: 30px;
    padding: 0 29px 0 20px;
}

/* Dropdown Icon */
.ui.selection.dropdown>.dropdown.icon {
    color: @omniTextColor;
}

/* Dropdown List Item */
.ui.selection.dropdown .menu>.item {
    padding: @10px @20px !important;
}

/* Add Dropdown error border */
.ui.error.selection.dropdown {
    border: 1px solid #FA263C;
}

/* Dropdown Label */
.ui.multiple.dropdown > .label  {
    font-weight: @bold;
    color: #FFFFFF;
    background-color: #F58B1F;
}

/* Dropdown loading spinner */
.ui.loading.selection.dropdown > i.icon {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
