/*******************************
         Theme Overrides
*******************************/

/* label */
.ui.form .field > label {
    float: left;
}

/* Error */
.ui.form .error .field{
    margin: 0;
}

.ui.form .field.error input {
    border: 1px solid @negativeBorderColor;
}