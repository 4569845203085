/*----------------
    Overrides
------------------*/

/* Tab Menu */
.ui.secondary.pointing.menu {
    margin: 0;
}

/* Tab active segment */
.ui.tab.active {
    margin: 0;
}

//Deactive Tab Item
.ui.secondary.pointing.menu .item {
    font-weight: bold;
    font-size: 16px;
    color: #69777d;
    cursor: pointer;
}

//hover
.ui.secondary.pointing.menu .item:hover {
    border-color: #2392d4;
}

//Active Tab Item
.ui.secondary.pointing.menu .active.item {
    color: #3e78bb;
    border-color: #3e78bb;
}

.ui.secondary.pointing.menu .active.item:hover {
    color: #3e78bb;
    border-color: #3e78bb;
}


/* Menu For Custom Header */
.ui.inverted.menu.omni-nav {
    padding: 0 40px 0 100px;
    margin: 0;
    border-bottom: 0.5px solid #D8D8D8;
    border-radius: 0px;
}

.ui.inverted.menu.omni-nav > div.item.logo {
    padding: 0;
}

.ui.inverted.menu.omni-nav > .item:before {
    background: none;
}

.ui.inverted.menu.omni-nav > .right.menu > .dropdown:before {
    background: none;
}
