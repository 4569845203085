/************************
         Theme 
*************************/

/* Variables */
@borderRadius: @3px;
@primaryTextColor: #FFFFFF;
@primaryPressedColor: #1D7DB5;
@secondaryTextColor: #4C5F68;
@secondaryBackgroundColor: #B7CED7;
@secondaryPressedColor: #A2B7C0;
@basicBackground: white;

/*-------------------
       Element
--------------------*/
@verticalPadding: @5px; 
@horizontalPadding: @14px;
@boxShadow: 0;
@basicTextColor: @secondaryTextColor;
@basicFontWeight: @bold;
@hoverBoxShadow: 0 1px 2px 0 rgba(32,32,32,0.50);

/* Button */
@lineHeight: 1em;

/*-------------------
       Primary
--------------------*/
@primaryColorFocus: @primaryPressedColor;
@primaryColorDown: @primaryPressedColor;
@primaryColorHover: @primaryColor;

/*-------------------
      Secondary
--------------------*/
@secondaryColorFocus:  @secondaryBackgroundColor;
@secondaryColorDown: @secondaryPressedColor;
@secondaryColorHover: @secondaryBackgroundColor;

/*----------------
       Basic
----------------*/
@basicFocusBackground: #FAFAFA;
@basicDownBackground: @secondaryPressedColor;
@basicFocusTextColor: @secondaryTextColor;

/*----------------
       Toggle
----------------*/
@positiveColor: @primaryColor;
@invertedTextShadow none;
@invertedTextColor: @primaryTextColor;
@positiveColorHover: @primaryColor;