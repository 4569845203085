/*******************************
         Theme Overrides
*******************************/

.ui.segment.secondary {
  background-color: @omniSecondaryBackgroundColor;
}

.ui.segment.tertiary {
  background-color: @omniTertiaryBackgroundColor;
}

.ui.segment.tertiary {
  background-color: @omniTertiaryBackgroundColor;
}