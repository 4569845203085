/*-------------------
     Overrides
--------------------*/

h1.ui.header {
  font-weight: lighter;
  font-size: 30px;
}

h2.ui.header {
  font-weight: bold;
  font-size: 24px;
}

h3.ui.header {
  font-weight: lighter;
  font-size: 20px;
}

h4.ui.header {
  font-weight: bold;
  font-size: 20px;
}

h5.ui.header {
  font-weight: lighter;
  font-size: 18px;
}
