/*******************************
         Theme Overrides
*******************************/

/*-------------------
       Generic
-------------------*/
.ui.button {
    min-height: @30px;
    //margin: @10px;
}

.ui.button:hover {
  box-shadow: @hoverBoxShadow !important;
}

/*----------------
       Basic
----------------*/
.ui.basic.button {
    border: 1px solid @secondaryTextColor; 
}

.ui.basic.button:focus {
    border-color: transparent;
    background-color: @secondaryBackgroundColor !important;
    color: @secondaryTextColor !important;
}

/*----------------
    Secondary
----------------*/
.ui.secondary.button:hover {
    border-color: transparent;
    background-color: @secondaryBackgroundColor !important;
    color: @secondaryTextColor !important;
}

/*------------------
    Toggle button
-------------------*/
.ui.buttons.toggleSearchBtn > button.ui.button {
    padding: 0 0.9rem;
    margin: 0;
    font-weight: normal;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24);
    color: #4C5F68;
}

.ui.buttons.toggleSearchBtn > button.ui.button:hover {
    color: #FFFFFF;
    background-color: @primaryColor;
}

.ui.buttons.toggleSearchBtn > button.ui.active.button {
    color: #FFFFFF;
    background-color: @primaryColor;
}
