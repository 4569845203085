/*****************************
     Modal Overrides Theme
*****************************/
/*------------------
        Header
------------------*/
/* Generic */
.ui.modal>.header {
    /* display: flex;
    justify-content: space-between; */
    align-items: baseline;
    color: @omniErrorModalHeaderTextColor;
}

/* Error */
.ui.modal.errorModal>.header {
    border-radius: @borderRadius;
    padding: @10px @omniModalPadding;
    border: @omniModalTypesBorder;
    background-color: @omniErrorModalHeaderBackgroundColor ;
}

/* Warning */
.ui.modal.warningModal>.header {
    border-radius: @borderRadius;
    padding: @10px @omniModalPadding;
    border: @omniModalTypesBorder;
    background-color: @omniWarningModalHeaderBackgroundColor;
}

/* Success */
.ui.modal.successModal>.header {
    border-radius: @borderRadius;
    padding: @10px @omniModalPadding;
    border: @omniModalTypesBorder;
    background-color: @omniSuccessModalHeaderBackgroundColor;
}

.ui.modal i.close.icon {
  top: 6px;
  right: 6px;
}

.ui.modal .header .icon {
  font-size: inherit;
}


