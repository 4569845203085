/*******************************
         Tab Overrides
*******************************/

.ui.pointing.secondary.menu {
  border-bottom: 2px solid lightgray;
}

.ui.secondary.menu .item {
  padding: 0 15px;
}

.subtab .ui.secondary.menu {
  margin: 0;
  min-height: auto;
}

.subtab .ui.secondary.menu .item {
  margin: 0 0 20px 0;
  border-radius: 0;
  background-color: rgba(111, 136, 150, 0.4);
  line-height: 40px;
  height: 40px;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.subtab .ui.secondary.menu .item:hover,
.subtab .ui.secondary.menu .item.active {
  color: black;
  background-color: #7FB1CE;
}

.subtab .ui.secondary.menu .item:active {
  color: white;
  background-color: #4A98C6;
}
